<!--
--------------------------------------------------------------------------
   Items.vue
--------------------------------------------------------------------------

    Component to show items of a section or o an item

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="produccio" :class="{ 'mt-5' : item !== null && item.type !== 'activity', 'mt-3': item !== null && item.type === 'activity' }">
        <loading v-if="loading"></loading>
        <div class="row" v-if="items.length > 0">
            <div class="col-9 col-lg-8 col-xl-9">
                <h3 v-if="item !== null && showTitle"><translate>Scientific production</translate></h3>

                <!-- Numeration, alphabetic and sort -->
                <div class="container mb-3" v-if="items.length > 0">
                    <div class="row numbers-sort">
                        <div class="col-12 col-sm-3 text-left"><strong>{{ formData.page.start }}</strong> a <strong>{{ formData.page.end }}</strong> de <strong>{{ this.formData.page.total }}</strong> resultats</div>
                        <div class="col-12 col-sm-9 text-right">
                            <form class="form-inline" role="form" rel="nofollow">
                                <label for="alphabetic" v-if="section !== null && section.showAlphabetic"><translate>Alphabetic</translate>:</label>
                                <select id="alphabetic" name="alphabetic" size="1" class="form-control form-control-sm mr-2" @change="changeLetter" v-model="formData.letter" v-if="section !== null && section.showAlphabetic">
                                    <option value="" selected="selected"><translate>Any</translate></option>
                                    <option :value="letter" v-for="letter in alphabet" :key="'letter.' + letter">{{ letter.toUpperCase() }}</option>
                                </select>
                                <label for="sort"><translate>Sort by:</translate></label>
                                <select id="sort" name="sort" size="1" class="form-control form-control-sm" v-model="formData.sort" @change="changeSort">
                                    <option :value="sortOption.value" v-for="(sortOption, index) in sortOptions" :key="'sortOption.' + index">{{ sortOption.text }}</option>
                                </select>
                                <b-button squared variant="outline-secondary" size="sm" class="filters-button ml-3" @click="doShowFilters();">
                                    <i class="fas fa-filter"></i>
                                </b-button>
                            </form>
                        </div>
                    </div>
                </div>

                <!-- List of items -->
                <div id="items">
                    <ul class="list">
                        <b-media tag="li" right-align vertical-align="center" v-for="(item, index) in items" :key="'item.' + item.id" :id="'item-' + item.id" :class="'item-' + item.type + '-' + item.subtype">
                            <template #aside v-if="((section !== null && section.showItemImages === true) || item !== null) && getItemFieldValue(item, 'image', $language.current, false) !== null && imagesLoaded[index]">
                                <router-link :to="getItemURL(item).replace($store.state.config.URL, '')">
                                    <b-img-lazy :src="getItemFieldValue(item, 'image', $language.current, false).startsWith('http') ? getItemFieldValue(item, 'image', $language.current, false) : $store.state.config.URL + '/images/items/' + getItemFieldValue(item, 'image', $language.current, false)" :alt="getItemFieldValue(item, 'title', $language.current, false)" :title="getItemFieldValue(item, 'title', $language.current, false)" @error.native="hideImage(index)" :class="item.type"></b-img-lazy>
                                </router-link>
                            </template>
                            <h5 class="mt-0">
                                <router-link :to="getItemURL(item).replace($store.state.config.URL, '')">{{ getItemFieldValue(item, 'title', $language.current, false) }}</router-link>
                                <i class="fas fa-lock-open open-access" v-if="getItemFieldValue(item, 'openAccess', $language.current, false) === '1'"></i>
                            </h5>
                            <p v-html="printItemDescription(item, section)"></p>
                            <itemListTools :item="item" v-if="item.type === 'person' || item.type === 'organization' || item.type === 'activity'"></itemListTools>
                        </b-media>
                    </ul>
                </div>

                <!-- Pagination -->
                <div class="overflow-auto">
                    <b-pagination-nav :link-gen="changePage" :number-of-pages="formData.page.numPages" use-router>
                        <template #first-text>
                            <span aria-hidden="true" role="presentation" class="custom-icon">&#xe92e;</span>
                        </template>
                        <template #prev-text>
                            <span aria-hidden="true" role="presentation" class="custom-icon">&#xe90c;</span>
                        </template>
                        <template #next-text>
                            <span aria-hidden="true" role="presentation" class="custom-icon">&#xe92c;</span>
                        </template>
                        <template #last-text>
                            <span aria-hidden="true" role="presentation" class="custom-icon">&#xe92f;</span>
                        </template>
                    </b-pagination-nav>
                </div>

            </div>

            <!-- Export and filters -->
            <div class="col-3 col-lg-4 col-xl-3" :class="{ 'show-filters' : showFilters }">

                <!-- Button to close filters in moblie devices -->
                <b-button squared variant="light" class="mb-3 w-100 d-lg-none" @click="doCloseFilters();">
                    <i class="fas fa-times"></i> <translate>Close</translate>
                </b-button>

                <!-- Export options -->
                <b-dropdown id="export-options" :text="'Export'|translate" class="w-100">
                    <b-dropdown-item v-if="(section !== null && section.itemType === 'activity') || item !== null || simulation !== null || searchType === 'activity'" v-b-modal.export-bibtex>
                        <i class="fas fa-asterisk"></i><translate>BIBTex</translate>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="(section !== null && section.itemType === 'activity') || item !== null || simulation !== null || searchType === 'activity'" v-b-modal.export-ris>
                        <i class="fas fa-book-open"></i><translate>Reference manager</translate>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="(section !== null && section.itemType === 'activity') || item !== null || simulation !== null || searchType === 'activity'" v-b-modal.export-xls>
                        <i class="far fa-file-excel"></i><translate>Excel</translate>
                    </b-dropdown-item>
                    <b-dropdown-item @click="exportData($store.state.config.URL + $route.path, 'RSS')">
                        <i class="fas fa-rss"></i><translate>RSS feed</translate>
                    </b-dropdown-item>
                    <b-dropdown-item v-b-modal.export-frame>
                        <i class="fas fa-code"></i><translate>Embed in a web</translate>
                    </b-dropdown-item>
                    <b-dropdown-item v-b-modal.export-save-search v-if="this.$store.state.user.username !== ''">
                        <i class="fas fa-save"></i><translate>Save</translate>
                    </b-dropdown-item>
                    <b-dropdown-item v-b-modal.export-qr>
                        <i class="fas fa-qrcode"></i><translate>QR</translate>
                    </b-dropdown-item>
                </b-dropdown>

                <b-modal id="export-bibtex" :title="'BIBTex'|translate" ok-only :ok-title="'Ok'|translate" @ok="exportData($store.state.config.URL + $route.path, 'bibtex')">
                    <translate>A maximum of</translate> {{ $store.state.config.maxItemsPage }} <translate>records can be exported. If the search returns more than this number of records, only the first</translate> {{ $store.state.config.maxItemsPage }} <translate>will be exported.
                    Only articles, chapters of exhibition catalog, book chapters, theses, books, reports, exhibition catalogs and presentations of work at congresses are exported because the rest of the scientific production is not comptaible with BibTeX format.</translate>
                </b-modal>

                <b-modal id="export-ris" :title="'Reference manager'|translate" ok-only :ok-title="'Ok'|translate" @ok="exportData($store.state.config.URL + $route.path, 'RIS')">
                    <translate>A maximum of</translate> {{ $store.state.config.maxItemsPage }} <translate>records can be exported. If the search returns more than this number of records, only the first</translate> {{ $store.state.config.maxItemsPage }} <translate>will be exported.
                    The collaborations in exhibitions, collaborations in journals, awards or recognitions and projects were not exported because they are not comptabiles with the formats of reference managers.</translate>
                </b-modal>

                <b-modal id="export-xls" :title="'Excel'|translate" ok-only :ok-title="'Ok'|translate" @ok="exportData($store.state.config.URL + $route.path, 'xls')">
                    <translate>A maximum of</translate> {{ $store.state.config.maxItemsPage }} <translate>records can be exported. If the search returns more than this number of records, only the first</translate> {{ $store.state.config.maxItemsPage }} <translate>will be exported.</translate>
                </b-modal>

                <b-modal id="export-qr" :title="'QR Code'|translate" ok-only :ok-title="'Close'|translate">
                    <div class="text-center">
                        <VueQrcode :value="$store.state.config.URL + $route.path"></VueQrcode>
                    </div>
                </b-modal>

                <b-modal id="export-frame" :title="'Embed in a web'|translate" ok-only :ok-title="'Close'|translate">
                    <div class="text-left">
                        <translate>Embed this code in your page:</translate><br /><br />
                        <pre>{{ frameCode }}</pre>
                    </div>
                </b-modal>

                <b-modal id="export-save-search" :title="'Save search'|translate">
                    <b-form @submit.stop.prevent="saveUserSearch" novalidate>
                        <b-form-group id="group-title" :label="'Title'|translate" label-for="title">
                            <b-form-input id="title" name="title" v-model="$v.userSearchData.title.$model" type="text" :placeholder="'Title'|translate" :state="validateState('title')"></b-form-input>
                            <b-form-invalid-feedback id="title"><translate>Title is required.</translate></b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-checkbox id="alert" v-model="$v.userSearchData.alert.$model" name="alert" :value="true" :unchecked-value="false">
                            <translate>Receive periodic notifications of new results via email</translate>
                        </b-form-checkbox>
                        <br />
                    </b-form>
                    <template #modal-footer="{ cancel }">
                        <b-button type="submit" variant="primary" @click="saveUserSearch"><translate>Save</translate></b-button>
                        <b-button variant="secondary" @click="cancel()"><translate>Close</translate></b-button>
                    </template>
                </b-modal>

                <!-- Filters and collaborative network -->
                <div id="filters-collaborative-network-links">
                    <!-- Filters -->
                    <b-card id="filters" v-if="filters.filter(f => f.type === 1 && f.visible).length > 0">
                        <template #header>
                            <translate>Filter results</translate>
                        </template>
                        <b-card-text>
                            <div v-if="showFeatured" class="filter">
                                <h4>
                                    <translate>Featured scientific production</translate>&nbsp;
                                    <b-button squared variant="outline-secondary" size="sm" class="filter-info-button" v-b-modal="'filter-modal-featured'">
                                        <i class="fas fa-info"></i>
                                    </b-button>
                                </h4>
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);" @click="applyFeatured()" :class="{ 'selected' : featuredEnabled }" rel=”nofollow”><translate>Show only featured production</translate></a> <b-badge pill variant="danger" @click="deleteFeatured()" v-if="featuredEnabled">X</b-badge>
                                    </li>
                                </ul>
                                <b-modal id="filter-modal-featured" :title="'Information'|translate">
                                    <p class="my-4">
                                        <translate>Featured filter information</translate>
                                    </p>
                                </b-modal>
                            </div>
                            <div v-for="(filter, index) in filters.filter(f => f.type === 1 && f.values.length > 0 && f.visible)" :key="'filter.' + filter.type + '.' + index" class="filter">
                                <h4>
                                    {{ filter.facetName[$language.current] }}
                                    <b-button squared variant="outline-secondary" size="sm" class="filter-info-button" v-b-modal="'filter-modal-' + filter.type + '-' + index" v-if="filter.facetInfo !== undefined">
                                        <i class="fas fa-info"></i>
                                    </b-button>
                                </h4>
                                <ul>
                                    <li v-for="(value, index2) in filter.values" :key="'filter.' + filter.type + '.' + index + '.value.' + index2" :class="{ 'd-none' : !filter.opened && index2 >= $store.state.config.maxFilterValues }">
                                        <a href="javascript:void(0);" @click="applyFilter(filter.parameterName, value)" :class="{ 'selected' : value.selected }" rel=”nofollow”>{{ value.text }}</a> <b-badge pill variant="secondary">{{ value.num }}</b-badge> <b-badge pill variant="danger" @click="deleteFilter(filter.parameterName)" v-if="value.selected">X</b-badge><router-link :to="'/' + value.id" v-if="value.id !== undefined && value.id !== '0'" class="filter-link-item"><i class="fas fa-file-alt"></i></router-link>
                                    </li>
                                </ul>
                                <div v-if="filter.values.length > $store.state.config.maxFilterValues">
                                    <b-button size="sm" variant="outline-secondary" v-if="!filter.opened" class="more-button" @click="togleFilter(filter)"><translate>More</translate>...</b-button>
                                    <b-button size="sm" variant="outline-secondary" v-else class="close-button" @click="togleFilter(filter)"><translate>Close</translate></b-button>
                                </div>
                                <b-modal :id="'filter-modal-' + filter.type + '-' + index" :title="'Information'|translate" v-if="filter.facetInfo !== undefined">
                                    <p class="my-4" v-html="filter.facetInfo[$language.current]"></p>
                                </b-modal>
                            </div>
                        </b-card-text>
                    </b-card>

                    <!-- Collaborative network -->
                    <b-card id="collaborative-network" v-if="filters.filter(f => f.type === 2 && f.visible).length > 0">
                        <template #header>
                            <translate>Collaboration network</translate>
                        </template>
                        <b-card-text>
                            <div v-for="(filter, index) in filters.filter(f => f.type === 2 && f.values.length > 0 && f.visible)" :key="'filter.' + filter.type + '.' + index" class="filter">
                                <h4>
                                    {{ filter.facetName[$language.current] }}
                                    <b-button squared variant="outline-secondary" size="sm" class="filter-info-button" v-b-modal="'filter-modal-' + filter.type + '-' + index" v-if="filter.facetInfo !== undefined">
                                        <i class="fas fa-info"></i>
                                    </b-button>
                                </h4>
                                <ul>
                                    <li v-for="(value, index2) in filter.values" :key="'filter.' + filter.type + '.' + index + '.value.' + index2" :class="{ 'd-none' : !filter.opened && index2 >= $store.state.config.maxFilterValues }">
                                        <a href="javascript:void(0);" @click="applyFilter(filter.parameterName, value)" :class="{ 'selected' : value.selected }" rel=”nofollow”>{{ value.text }}</a> <b-badge pill variant="secondary">{{ value.num }}</b-badge> <b-badge pill variant="danger" @click="deleteFilter(filter.parameterName)" v-if="value.selected">X</b-badge><router-link :to="'/' + value.id" v-if="value.id !== undefined && value.id !== '0'" class="filter-link-item"><i class="fas fa-file-alt"></i></router-link>
                                    </li>
                                </ul>
                                <div v-if="filter.values.length > $store.state.config.maxFilterValues">
                                    <b-button size="sm" variant="outline-secondary" v-if="!filter.opened" class="more-button" @click="togleFilter(filter)"><translate>More</translate>...</b-button>
                                    <b-button size="sm" variant="outline-secondary" v-else class="close-button" @click="togleFilter(filter)"><translate>Close</translate></b-button>
                                </div>
                                <b-modal :id="'filter-modal-' + filter.type + '-' + index" :title="'Information'|translate" v-if="filter.facetInfo !== undefined">
                                    <p class="my-4" v-html="filter.facetInfo[$language.current]"></p>
                                </b-modal>
                            </div>
                        </b-card-text>
                    </b-card>

                    <!-- Additional links -->
                    <b-card id="additional-links" v-if="section !== null && section.links.length > 0">
                        <template #header>
                            <translate>More about</translate> {{ section.fullName[$language.current].toLowerCase() }}
                        </template>
                        <b-card-text>
                            <div class="links">
                                <ul>
                                    <li v-for="(link, index) in section.links" :key="'link.' + index">
                                        <a :href="link.link[$language.current]" rel=”nofollow”>{{ link.name[$language.current] }}</a>
                                    </li>
                                </ul>
                            </div>
                        </b-card-text>
                    </b-card>
                </div>
            </div>
        </div>

        <!-- Not found error -->
        <b-alert show variant="danger" class="mt-4" v-if="!loading && items.length === 0 && item === null">
            <translate>No results found.</translate>
        </b-alert>
    </div>
</template>

<script>
    import Loading from '@/components/Loading';
    import ItemListTools from '@/components/ItemListTools';
    import VueQrcode from 'vue-qrcode';
    import { validationMixin } from 'vuelidate';
    import { required } from 'vuelidate/lib/validators';

    export default {
        name: 'items',
        props: ['section','item','searchType','showFeatured','featuredDefault','showTitle','simulation'],
        mixins: [validationMixin],
        components: { Loading, ItemListTools, VueQrcode },
        data() {
            return {
                loading: true,
                items: [],
                filters: [],
                featuredEnabled: this.featuredDefault,
                formData: {
                    query: '*:*',
                    facets: [],
                    sort: '',
                    page: {
                        pageIdx: 0,
                        pageSize: 1,
                        total: 0,
                        start: 0,
                        end: 0,
                        numPages: 5
                    },
                    text: '',
                    letter: ''
                },
                userSearchData: {
                    title: '',
                    url: '',
                    alert: false,
                    numResults: 0,
                    lastCheck: '',
                    language: this.$language.current
                },
                sortOptions: [],
                alphabet: Array.from(Array(26)).map((e, i) => i + 65).map((x) => String.fromCharCode(x)),
                imagesLoaded: [],
                frameCode: '<iframe width="100%" height="400" src="' + this.$store.state.config.URL + this.$route.path + '/frame/1" style="border: 0;" allowFullScreen></iframe>',
                showFilters: false
            };
        },
        validations: {
            userSearchData: {
                title: { required },
                alert: {}
            }
        },
        mounted() {
            // Set page size
            this.formData.page.pageSize = this.$store.state.config.defaultPageSize * 1;

            // Init filters, facets and sort
            this.init();

            // Execute search
            this.search();
        },
        methods: {
            /**
             * Inits component data
             */
            init() {
                // Clear items and filters
                this.items = [];
                this.filters = [];
                this.sortOptions = [];
                this.showFilters = false;

                // Check if is an item profile or a section
                // Items of a section
                if (this.section !== null) {
                    // Prepare sort options
                    this.section.sorts.forEach(s => {
                        let field = this.$store.state.fields.find(f => f.sort !== undefined && f.itemType === s.itemType && f.name === s.fieldName);
                        let fieldname = this.getFieldname(s.fieldName, s.itemType);
                        this.sortOptions.push({
                            text: field.sort.sortName[this.$language.current] + ' asc.',
                            value: fieldname + '_sort asc'
                        });
                        this.sortOptions.push({
                            text: field.sort.sortName[this.$language.current] + ' desc.',
                            value: fieldname + '_sort desc'
                        });
                    });

                    // Sort section links
                    this.section.links.sort((a, b) => {
                        if (a.ord > b.ord) return 1;
                        else return -1;
                    });
                // Scientific production of an item
                } else if (this.item !== null || this.simulation !== null) {
                    let sortFields = this.$store.state.fields.filter(f => f.sort !== undefined && f.itemType === 'activity');
                    sortFields.forEach(f => {
                        let fieldname = this.getFieldname(f.name, 'activity');
                        this.sortOptions.push({
                            text: f.sort.sortName[this.$language.current] + ' asc.',
                            value: fieldname + '_sort asc'
                        });
                        this.sortOptions.push({
                            text: f.sort.sortName[this.$language.current] + ' desc.',
                            value: fieldname + '_sort desc'
                        });
                    });
                // Search
                } else {
                    let sortFields = this.$store.state.fields.filter(f => f.sort !== undefined && f.itemType === this.searchType);
                    sortFields.forEach(f => {
                        let fieldname = this.getFieldname(f.name, this.searchType);
                        this.sortOptions.push({
                            text: f.sort.sortName[this.$language.current] + ' asc.',
                            value: fieldname + '_sort asc'
                        });
                        this.sortOptions.push({
                            text: f.sort.sortName[this.$language.current] + ' desc.',
                            value: fieldname + '_sort desc'
                        });
                    });
                }

                // Prepare facets
                this.prepareFacetsFilters();
            },
            /**
             * Search items
             */
            search() {
                // Set loading
                this.loading = true;

                // Reset filter values
                this.filters.map(f => f.values = []);

                // Process parameters and prepare query
                this.processParametersQuery();

                // Search items
                this.axios.post(this.apiURL + '/items/search', this.formData)
                    .then(response => {
                        this.loading = false;

                        // Get items in response
                        let items = [];
                        this.imagesLoaded = [];
                        response.data.content.forEach(item => {
                            items.push(item);
                            this.imagesLoaded.push(true);
                        });
                        this.items = items;

                        // Get facet values in response
                        this.filters.forEach(filter => {
                            // Get values
                            let facetResponse = response.data.facets.find(rf => rf.length > 0 && rf[0].field.name === filter.facetField);
                            if (facetResponse !== undefined) {
                                let filterValues = [];
                                facetResponse.forEach(value => {
                                    let valueParts = value.value.split('##');
                                    let text = valueParts[0];
                                    if (filter.translate) text = this.$gettext(filter.fieldname + '_' + text);
                                    if (valueParts.length > 1) {
                                        // Only add value if not is the same item showed
                                        if (this.item === null || (this.item !== null && this.item.id !== valueParts[1])) {
                                            if (valueParts[1] !== 0) filterValues.push({ value: valueParts[0], text: text, num: value.valueCount, id: valueParts[1], type: valueParts[2], selected: valueParts[0] === filter.selectedValue || valueParts[0].toLowerCase() === filter.selectedValue });
                                            else filterValues.push({ value: valueParts[0], text: text, num: value.valueCount, selected: valueParts[0] === filter.selectedValue || valueParts[0].toLowerCase() === filter.selectedValue });
                                        }
                                    } else filterValues.push({ value: valueParts[0], text: text, num: value.valueCount, selected: valueParts[0] === filter.selectedValue || valueParts[0].toLowerCase() === filter.selectedValue });
                                });

                                // Invert values order if necessary
                                if (filter.inverseOrder) filterValues = filterValues.reverse();

                                filter.values = filterValues;
                            }
                        });

                        // Get total items and calculate pagination
                        this.formData.page.total = response.data.totalElements;
                        if (this.formData.page.total < this.formData.page.end) this.formData.page.end = this.formData.page.total;
                        this.formData.page.numPages = Math.ceil(this.formData.page.total / this.formData.page.pageSize);

                        // Add scripts
                        this.addScripts();

                        // Update frame code
                        this.frameCode = '<iframe width="100%" height="400" src="' + this.$store.state.config.URL + this.$route.path + '/frame/1" style="border: 0;" allowFullScreen></iframe>';
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Prepare filters and facets
             */
            prepareFacetsFilters() {
                // Reset arrays
                this.formData.facets = [];
                this.filters = [];

                // Check if is an item profile or a section
                if (this.section !== null) {
                    // Get sections facets
                    this.section.facets.forEach(fc => {
                        let field = this.$store.state.fields.find(f => f.itemType === fc.itemType && f.name === fc.fieldName);

                        // Add facet to search data
                        this.formData.facets.push({
                            field: this.getFieldname(fc.fieldName, fc.itemType) + '_facet',
                            minCount: 1,
                            order: field.facet.valuesOrder,
                            limit: field.facet.lim
                        });

                        // Add filter
                        let filter = field.facet;
                        filter.facetField = this.getFieldname(fc.fieldName, fc.itemType) + '_facet';
                        filter.ord = fc.ord;
                        filter.visible = fc.visible;
                        filter.values = [];
                        filter.selectedValue = '';
                        filter.translate = field.translate;
                        filter.fieldname = fc.fieldName;
                        this.filters.push(filter);
                    });
                } else if (this.item !== null || this.simulation !== null) {
                    let facetFields = this.$store.state.fields.filter(f => f.itemType === 'activity' && f.facet !== undefined);
                    facetFields.forEach(fc => {
                        // Hiden filter person in organizations
                        if (this.item.type !== 'organization' || fc.name !== 'person') {
                            // Add facet to search data
                            this.formData.facets.push({
                                field: this.getFieldname(fc.name, fc.itemType) + '_facet',
                                minCount: 1,
                                order: fc.facet.valuesOrder,
                                limit: fc.facet.lim
                            });

                            // Add filter
                            let filter = fc.facet;
                            filter.facetField = this.getFieldname(fc.name, fc.itemType) + '_facet';
                            filter.visible = fc.facet.visible;
                            filter.values = [];
                            filter.selectedValue = '';
                            filter.translate = fc.translate;
                            if (!filter.translate) filter.fieldname = this.getFieldname(fc.name, fc.itemType);
                            else filter.fieldname = fc.name;
                            this.filters.push(filter);
                        }
                    });
                } else {
                    let facetFields = this.$store.state.fields.filter(f => f.itemType === this.searchType && f.facet !== undefined);
                    facetFields.forEach(fc => {
                        // Add facet to search data
                        this.formData.facets.push({
                            field: this.getFieldname(fc.name, fc.itemType) + '_facet',
                            minCount: 1,
                            order: fc.facet.valuesOrder,
                            limit: fc.facet.lim
                        });

                        // Add filter
                        let filter = fc.facet;
                        filter.facetField = this.getFieldname(fc.name, fc.itemType) + '_facet';
                        filter.visible = fc.facet.visible;
                        filter.values = [];
                        filter.selectedValue = '';
                        filter.translate = fc.translate;
                        if (!filter.translate) filter.fieldname = this.getFieldname(fc.name, fc.itemType);
                        else filter.fieldname = fc.name;
                        this.filters.push(filter);
                    });
                }

                // Sort filters
                this.filters.sort((a, b) => {
                    if (a.ord > b.ord) return 1;
                    else return -1;
                });
            },
            /**
             * Opens or clases a filter to show / hide values
             */
            togleFilter(filter) {
                filter.opened = !filter.opened;
            },
            /**
             * Process route parameters
             */
            processParametersQuery() {
                // Set default data
                this.formData.page.pageIdx = 0;
                if (this.section !== null) this.formData.query = this.section.content[this.$language.current];
                else if (this.item !== null) {
                    if (this.item.type === 'person') this.formData.query = '+' + this.getFieldname('personProfile', 'activity') + '_str:' + this.filterQuery(this.getItemFieldValue(this.item, 'title', this.$language.current, false) + '##*');
                    else if (this.item.type === 'organization') this.formData.query = '+' + this.getFieldname(this.item.subtype, 'activity') + '_str:' + this.filterQuery(this.getItemFieldValue(this.item, 'title', this.$language.current, false) + '##*');
                    else if (this.item.type === 'journal') this.formData.query = '+' + this.getFieldname('journal', 'activity') + '_str:"' + this.getItemFieldValue(this.item, 'title', this.$language.current, false) + '"';
                    else this.formData.query = '+' + this.getFieldname('project', 'activity') + '_str:"' + this.getItemFieldValue(this.item, 'title', this.$language.current, false) + '"';
                } else if (this.simulation !== null) {
                    let queryComponents = [];
                    this.simulation.components.forEach(c => {
                        if (c.type === 'person') queryComponents.push('activity_' + this.$language.current + '_person_str:' + this.filterQuery(c.title) + '##*');
                        else queryComponents.push('activity_' + this.$language.current + '_' + c.type + '_str:' + this.filterQuery(c.title) + '##*');
                    });
                    this.formData.query = '+(' + queryComponents.join(' OR ') + ')';
                } else this.formData.query = '';
                this.formData.letter = '';
                this.formData.text = '';
                this.filters.map(f => f.selectedValue = '');

                // Select default sort
                let sort = '';
                if (this.section !== null) {
                    if (this.section.sorts.length > 0) {
                        let sortField = this.section.sorts.find(s => s.ord === 1);
                        if (sortField === null) sortField = this.section.sorts[0];
                        let field = this.$store.state.fields.find(f => f.sort !== undefined && f.itemType === sortField.itemType && f.name === sortField.fieldName);
                        sort = this.getFieldname(sortField.fieldName, this.section.itemType) + '_sort ' + field.sort.defaultDirection;
                    } else sort = this.getFieldname('title', this.section.itemType) + '_sort asc';
                } else if (this.item !== null || this.simulation !== null) {
                    let sortFields = this.$store.state.fields.filter(f => f.itemType === 'activity' && f.sort !== undefined);
                    if (sortFields.length > 0) {
                        let sortField = sortFields.find(s => s.sort.ord === 1);
                        if (sortField === null) sortField = sortFields[0];
                        sort = this.getFieldname(sortField.name, 'activity') + '_sort ' + sortField.sort.defaultDirection;
                    } else sort = this.getFieldname('title', 'activity') + '_sort asc';
                } else {
                    let sortFields = this.$store.state.fields.filter(f => f.itemType === this.searchType && f.sort !== undefined);
                    if (sortFields.length > 0) {
                        let sortField = sortFields.find(s => s.sort.ord === 1);
                        if (sortField === null) sortField = sortFields[0];
                        sort = this.getFieldname(sortField.name, this.searchType) + '_sort ' + sortField.sort.defaultDirection;
                    } else sort = this.getFieldname('title', this.searchType) + '_sort asc';
                }
                this.formData.sort = sort;

                if (this.$route.params.paramValues !== undefined || this.$route.params.itemId !== undefined || this.$route.params.simulationId !== undefined) {
                    // Get parameters from url
                    let paramValues = [];
                    if (this.section !== null || (this.item === null && this.simulation === null)) {
                        paramValues = this.$route.params.paramValues.split('/');
                        this.$parent.showGraph = false;
                    } else if (this.simulation !== null) {
                        paramValues = this.$route.params.simulationId.split('/');
                        paramValues.shift();
                        this.$root.$emit('showAdditionalData', false);
                    } else {
                        paramValues = this.$route.params.itemId.split('/');
                        paramValues.shift();
                        // Show item additional data, only if no filters are selected
                        if (paramValues.length > 0) this.$root.$emit('showAdditionalData', false);
                        else this.$root.$emit('showAdditionalData', true);
                    }

                    // Parse parameters to find values
                    for (let i = 0; i < paramValues.length; i += 2) {
                        // Pagination parameter
                        if (paramValues[i] === 'p') this.formData.page.pageIdx = paramValues[i + 1] - 1;
                        // Text search parameter (base64)
                        else if (paramValues[i] === 't' || paramValues[i] === 'ost') {
                            if (paramValues[i] === 't') this.formData.text = this.b64ToUTF8(paramValues[i + 1]);
                            else this.formData.text = paramValues[i + 1];
                            this.$store.commit('setSearchText', this.formData.text.trim());
                            // Check if text starts with " (exact search)
                            if (this.formData.text.startsWith('"')) {
                                if (this.section !== null || this.item !== null) this.formData.query += ' +(' + (this.section !== null ? this.section.itemType : this.item.type) + '_search:"' + this.formData.text.trim().replaceAll('"', '') + '")';
                                else if (this.simulation !== null) this.formData.query += ' +(activity_search:"' + this.formData.text.trim().replaceAll('"', '') + '")';
                                else this.formData.query += ' +(' + this.searchType + '_search:"' + this.formData.text.trim().replaceAll('"', '') + '")';
                            } else {
                                // Separate text in words and do a search with OR
                                let words = this.formData.text.trim().split(' ');
                                if (this.section !== null || this.item !== null) words.filter(w => w.trim() != '').forEach(w => this.formData.query += ' +(' + (this.section !== null ? this.section.itemType : this.item.type) + '_search:' + this.filterQuery(w) + ')');
                                else if (this.simulation !== null) words.filter(w => w.trim() != '').forEach(w => this.formData.query += ' +(activity_search:' + this.filterQuery(w) + ')');
                                else words.filter(w => w.trim() != '').forEach(w => this.formData.query += ' +(' + this.searchType + '_search:' + this.filterQuery(w) + ')');
                            }
                        }
                        // Letter parameter
                        else if (paramValues[i] === 'l') {
                            // Update parameter and query
                            this.formData.letter = paramValues[i + 1];
                            this.formData.query += ' +(' + this.getFieldname('title', (this.section !== null ? this.section.itemType : this.item.type)) + '_str:' + this.formData.letter.toUpperCase() + '* OR ' + this.getFieldname('title', (this.section !== null ? this.section.itemType : this.item.type)) + '_str:' + this.formData.letter.toLowerCase() + '*)';
                        }
                        // Featured parameter
                        else if (paramValues[i] === 'fsp') this.featuredEnabled = (paramValues[i + 1] === '1');
                        // Sort parameter
                        else if (paramValues[i] === 'o') this.formData.sort = this.b64ToUTF8(paramValues[i + 1]);
                        // Filter parameter
                        else {
                            // Search if filter with this parameterName exists
                            let index = this.filters.findIndex(f => f.parameterName === paramValues[i]);
                            if (index !== -1) {
                                this.filters[index].selectedValue = this.b64ToUTF8(paramValues[i + 1]);
                                this.formData.query += ' +(' + this.filters[index].facetField + ':' + this.filterQuery(this.filters[index].selectedValue) + '*)';
                            }
                        }
                    }
                } else {
                    this.$parent.showGraph = true;
                    this.$root.$emit('showAdditionalData', true);
                }

                //  If featured selected, then change query
                if (this.featuredEnabled) this.formData.query = '+' + this.getFieldname('personFeatured', 'activity') + '_str:' + this.filterQuery(this.getItemFieldValue(this.item, 'title', this.$language.current, false) + '##*');

                // Calculate first and end result
                this.formData.page.start = this.formData.page.pageIdx * this.formData.page.pageSize + 1;
                this.formData.page.end = this.formData.page.start + this.formData.page.pageSize - 1;
            },
            /**
             * Filter results by letter
             */
            changeLetter() {
                this.$router.push({ path: this.buildRoute([{ parameterName: 'l', value: this.formData.letter }, { parameterName: 'p', value: '' }]) });
            },
            /**
             * Change results sort criteria
             */
            changeSort() {
                this.$router.push({ path: this.buildRoute([{ parameterName: 'o', value: this.UTF8ToB64(this.formData.sort) }, { parameterName: 'p', value: '' }]) });
            },
            /**
             * Change current page of results
             */
            changePage(numPage) {
                if (numPage === 1) numPage = '';
                return this.buildRoute([{ parameterName: 'p', value: numPage }]);
            },
            /**
             * Applies a filter to search
             */
            applyFilter(parameterName, value) {
                if (!value.selected) this.$router.push({ path: this.buildRoute([{ parameterName: parameterName, value: this.UTF8ToB64(value.value) }, { parameterName: 'p', value: '' }]) });
            },
            /**
             * Delete a filter to search
             */
            deleteFilter(parameterName) {
                this.$router.push({ path: this.buildRoute([{ parameterName: parameterName, value: '' }, { parameterName: 'p', value: '' }]) });
            },
            /**
             * Applies featured filter
             */
            applyFeatured() {
                this.$router.push({ path: '/' + this.getItemURL(this.item).replace(this.$store.state.config.URL + '/', '') + '/fsp/1' });
            },
            /**
             * Delete featured filter
             */
            deleteFeatured() {
                this.$router.push({ path: '/' + this.getItemURL(this.item).replace(this.$store.state.config.URL + '/', '') + '/fsp/0' });
            },
            /**
             * Hide item image in case of image is not loading
             */
            hideImage(index) {
                this.$set(this.imagesLoaded, index, false);
            },
            /**
             * Show filters in mobile devices
             */
            doShowFilters() {
                this.showFilters = true;
            },
            /**
             * Close filters in mobile devices
             */
            doCloseFilters() {
                this.showFilters = false;
            },
            /**
             * Add scripts to head
             */
            addScripts() {
                // Add mathjax
                let mathJaxScript = document.createElement('script');
                mathJaxScript.setAttribute('src', '//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-AMS-MML_HTMLorMML');
                document.head.appendChild(mathJaxScript);

                // Add altmetrics script
                let altmetricsScript = document.createElement('script');
                altmetricsScript.setAttribute('src', 'https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js');
                document.head.appendChild(altmetricsScript);
            },
            /**
             * Validates if a form field has a invalida value or not
             */
            validateState(name) {
                const { $dirty, $error } = this.$v.userSearchData[name];
                return $dirty ? !$error : null;
            },
            /**
             * Saves the current search in users profile
             */
            saveUserSearch() {
                this.$v.userSearchData.$touch();
                // Check if form has errors
                if (!this.$v.userSearchData.$anyError) {
                    // Send data to save
                    this.userSearchData.url = this.$route.path.substring(1);
                    this.userSearchData.numResults = this.formData.page.total;
                    this.userSearchData.lastCheck = (new Date()).toISOString().slice(0, 16).replace('T', ' ');
                    this.axios.post(this.apiURL + '/users/' + this.$store.state.user.username + '/searches', this.cleanFormData(this.$v.userSearchData.$model))
                        .then(() => {
                            this.$bvModal.hide('export-save-search');
                            this.showMessage(this.$gettext('Saved successfully'), this.$gettext('Your search has been saved successfully.'), 'success', false);
                        })
                        .catch(error => {
                            this.processError(error);
                        });
                }
            }
        },
        watch: {
            /**
             * Monitoring route change to update parameters and search results
             */
            $route(from, to) {
                // If route change, then re-init data
                if (from.name !== to.name) {
                    this.init();
                }

                // Execute search
                this.search();
            }
        }
    };
</script>